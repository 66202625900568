import { useState } from "react"
import "./style.scss"

interface SwitchProps {
  id: string | number
  onChange: (id: string | number, isChecked: boolean) => void
}

export const Switch = ({ id, onChange }: SwitchProps) => {
  const [isChecked, setIsChecked] = useState(false)

  const handleToggle = () => {
    setIsChecked((prev) => !prev)
    onChange(id, !isChecked)
  }

  return (
    <label className="switch">
      <input type="checkbox" checked={isChecked} onChange={handleToggle} />
      <span className="slider" />
    </label>
  )
}
