import { useEffect, useState } from "react"
import { Search } from "lucide-react"
import { StepIndicator } from "../StepIndicator/step-indicator"
import { UserProfileStep2 } from "./user-profile-step2"
import { Switch } from "../../../../features/Switch/switch"
import "./styles.scss"
import { PaginationV2 } from "../../../../features/PaginationV2"
import { useNavigate } from "react-router-dom"
import Input from "../../../../features/Input/index"
import Header from "../../../../features/Header"
import services from "../../../../../services/svcmesh"
import { OperadorPj } from "k4n-svcmesh-sdk"
import LoadingOverlay from "../../../../features/LoadingOverlay"

export const NewUser = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [resultsPerPage, setResultsPerPage] = useState(10)
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({
    // Step 1 data
    fullName: ``,
    email: ``,
    cpf: ``,
    phone: ``,
    validityDate: `${new Date().toISOString().split(`T`)[0]}`,
    searchGroup: ``,
    // Step 2 data
    profileType: `existing` as `existing` | `new`,
    selectedProfile: ``,
    permissions: {
      investments: false,
      digitalAccount: false,
    },
  })

  const navigate = useNavigate()

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleProfileTypeChange = (type: `existing` | `new`) => {
    setFormData((prev) => ({
      ...prev,
      profileType: type,
    }))
  }

  const handlePermissionToggle = (
    permission: `investments` | `digitalAccount`,
  ) => {
    setFormData((prev) => ({
      ...prev,
      permissions: {
        ...prev.permissions,
        [permission]: !prev.permissions[permission],
      },
    }))
  }

  const handleProfileSelect = (profile: string) => {
    setFormData((prev) => ({
      ...prev,
      selectedProfile: profile,
    }))
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (currentStep === 1) {
      setCurrentStep(2)
    } else {
      console.log(`Form submitted:`, formData)
      setIsLoading(true)
      const payload: OperadorPj | any = {
        numero_cartao: ``,
        bandeira_cartao: ``,
        apelido: ``,
        nome: formData.fullName,
        role: `OPERATORPJ`,
        emails: [
          {
            email: formData.email,
          },
        ],
        cpf: formData.cpf,
        phones: [
          {
            phone: formData.phone,
          },
        ],
        // Add other required properties with appropriate values
      }
      try {
        const response = await services.bankingService
          .createOperator(payload)
          .then((res) => {
            console.log(res)
            setIsLoading(false)
          })
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleBack = () => {
    if (currentStep === 2) {
      setCurrentStep(1)
    }
    if (currentStep === 1) {
      navigate(-1)
    }
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    // Here you would typically fetch new data based on the new page
  }

  const handleResultsPerPageChange = (value: number) => {
    setResultsPerPage(value)
    setCurrentPage(1) // Reset to first page when changing results per page
    // Here you would typically fetch new data based on the new resultsPerPage
  }

  const [switchStates, setSwitchStates] = useState<{ [key: number]: boolean }>(
    {},
  )

  useEffect(() => {
    const initialState = mockGroups.reduce((acc, group) => {
      acc[group.id] = false
      return acc
    }, {} as { [key: number]: boolean })

    setSwitchStates(initialState)
  }, [])

  const handleSwitchChange = (id: string | number, isChecked: boolean) => {
    setSwitchStates((prev) => ({
      ...prev,
      [id]: isChecked,
    }))
  }

  console.log(`Switches States: `, switchStates)

  const mockGroups = [
    {
      id: 1,
      name: `ATENA SOLUCOES INFORMATICA LTDA`,
    },
    {
      id: 2,
      name: `ATENA SOLUCOES LTDA`,
    },
    {
      id: 3,
      name: `ATENA INFORMATICA LTDA`,
    },
    {
      id: 4,
      name: `ATENA LTDA`,
    },
  ]

  const filteredGroups = mockGroups.filter((group) =>
    group.name.toLowerCase().includes(formData.searchGroup.toLowerCase()),
  )

  const renderStep1 = () => (
    <>
      <div className="form-header">
        <h1 className="title">Novo usuário</h1>
        <StepIndicator currentStep={currentStep} totalSteps={2} />
      </div>
      <div className="header-divider" />

      <div className="form-row">
        <div className="form-group">
          <Input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleInputChange}
            required
            label="Nome completo"
          />
        </div>

        <div className="form-group">
          <Input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            label="E-mail"
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group">
          <Input
            type="text"
            id="cpf"
            name="cpf"
            value={formData.cpf}
            onChange={handleInputChange}
            required
            label="CPF"
            htmlFor="cpf"
          />
        </div>

        <div className="form-group">
          <Input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            required
            label="Celular"
            htmlFor="phone"
          />
        </div>
      </div>
      {/*
      <div className="form-group">
        <Input
          type="date"
          id="validityDate"
          name="validityDate"
          onChange={handleInputChange}
          value={formData.validityDate}
          label="Data limite vigência"
          htmlFor="validityDate"
          required
        />
      </div>
    */}
      <div className="group-section">
        <h2 className="subtitle">Definição do grupo</h2>
        <p className="description">
          Selecione a quais grupos o usuário a ser cadastrado terá acesso
        </p>

        <div className="search-group">
          <input
            type="text"
            name="searchGroup"
            value={formData.searchGroup}
            onChange={handleInputChange}
            placeholder="Procurar grupo"
            className="search-input"
          />
        </div>

        <div className="company-list">
          <div className="company-item">
            <div className="company-info">
              {filteredGroups.map((group) => (
                <div key={group.id} className="company-item">
                  <span className="company-name">{group.name}</span>
                  <Switch id={group.id} onChange={handleSwitchChange} />
                </div>
              ))}
            </div>

            <PaginationV2
              currentPage={currentPage}
              totalPages={5}
              onPageChange={handlePageChange}
              totalResults={50}
              resultsPerPage={resultsPerPage}
              onResultsPerPageChange={handleResultsPerPageChange}
            />
          </div>
        </div>
      </div>
    </>
  )

  return (
    <>
      <Header />
      <div className="newuser-container user-management">
        <form onSubmit={handleSubmit} className="form">
          {currentStep === 1 ? (
            renderStep1()
          ) : (
            <UserProfileStep2
              formData={formData}
              onProfileTypeChange={handleProfileTypeChange}
              onPermissionToggle={handlePermissionToggle}
              onProfileSelect={handleProfileSelect}
            />
          )}

          <div className="form-actions">
            <div className="btn-back">
              <button
                type="button"
                className="button-secondary"
                onClick={handleBack}
              >
                VOLTAR
              </button>
            </div>
            <div className="continue-btn">
              <button
                disabled={isLoading}
                type="submit"
                className="button-primary"
              >
                {isLoading ? (
                  <LoadingOverlay />
                ) : currentStep === 1 ? (
                  `CONTINUAR`
                ) : (
                  `SALVAR USUÁRIO`
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
