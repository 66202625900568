import { createContext, useState, useContext, ReactNode } from "react"

interface DigitalSignatureContextType {
  showSmsConfirmation: boolean
  setShowSmsConfirmation: (value: boolean) => void
}

const DigitalSignatureContext = createContext<
  DigitalSignatureContextType | undefined
>(undefined)

export const DigitalSignatureContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [showSmsConfirmation, setShowSmsConfirmation] = useState(false)

  return (
    <DigitalSignatureContext.Provider
      value={{
        showSmsConfirmation,
        setShowSmsConfirmation,
      }}
    >
      {children}
    </DigitalSignatureContext.Provider>
  )
}

export const useDigitalSignature = (): DigitalSignatureContextType => {
  const context = useContext(DigitalSignatureContext)
  if (!context) {
    throw new Error(
      `useDigitalSignature must be used within a DigitalSignatureProvider`,
    )
  }
  return context
}
