import { useState } from "react"
import { useForm } from "react-hook-form"
import "./styles.scss"
import Header from "../../../features/Header"
import { useResposive } from "../../../../hooks/useResponsive"
import arrowDown from "../../../../assets/images/arrow_yellow_down.svg"
import SVGInject from "@iconfu/svg-inject"
import contaDigitalIcon from "../../../../assets/images/transfer.svg"

export const ProfileManagement = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { isMobile } = useResposive()
  const [inputFocus, setInputFocus] = useState(0)
  const [collapsedCategories, setCollapsedCategories] = useState({})
  const [selectedGrants, setSelectedGrants] = useState([])

  const handleFocus = (e) => {
    if (e.target.id === `profileName`) {
      setInputFocus(inputFocus === 1 ? 0 : 1)
    }
  }

  const onSubmit = (data) => {
    console.log(`Form Submitted`, { ...data, selectedGrants })
  }

  const mockGrantCategories = [
    {
      groupName: `Conta Digital`,
      icon: contaDigitalIcon,
      categories: [
        { categoryName: `Saldo`, grants: [`read`] },
        { categoryName: `Transferência`, grants: [`read`, `write`] },
        { categoryName: `Boleto`, grants: [`read`, `write`] },
      ],
    },
  ]

  const toggleCollapse = (categoryName) => {
    setCollapsedCategories((prev) => ({
      ...prev,
      [categoryName]: !prev[categoryName],
    }))
  }

  const handleGrantChange = (group, category, grant) => {
    setSelectedGrants((prev: any) => {
      const exists = prev.some(
        (item) =>
          item.group === group &&
          item.category === category &&
          item.grant === grant,
      )

      if (exists) {
        return prev.filter(
          (item) =>
            !(
              item.group === group &&
              item.category === category &&
              item.grant === grant
            ),
        )
      } else {
        return [...prev, { group, category, grant }]
      }
    })
  }

  return (
    <div className="pm-page-container">
      <Header />
      <div className="pm-management-container">
        <div className="pm-management-content">
          <div className="pm-management-header">
            <h1>Novo Perfil</h1>
          </div>
          <span className="description">
            Crie perfis de permissão para facilitar o controle sobre as
            funcionalidades dos usuários de sua Conta Digital PJ
          </span>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pm-manager">
              <div className="options">
                <div className="pm-input-container">
                  <label
                    className={
                      inputFocus === 1
                        ? `pm-input-label pm-show-input`
                        : `pm-input-label`
                    }
                    htmlFor="profileName"
                  >
                    Nome do perfil:
                  </label>
                  <input
                    id="profileName"
                    type="text"
                    placeholder="Digite o nome do perfil"
                    className="pm-input"
                    onFocus={handleFocus}
                    {...register(`profileName`, {
                      required: `Por favor, preencha o nome do perfil.`,
                      onBlur: () => setInputFocus(0),
                      minLength: {
                        value: 16,
                        message: `O nome do perfil deve ter pelo menos 16 caracteres.`,
                      },
                      maxLength: {
                        value: 120,
                        message: `O nome do perfil deve ter no máximo 120 caracteres.`,
                      },
                    })}
                  />
                  {errors.profileName && (
                    <span className="pm-error-msg">
                      {errors.profileName.message?.toString()}
                    </span>
                  )}
                </div>
                <div className="pm-select-container">
                  <label
                    className="pm-input-label pm-show-input"
                    htmlFor="profileStatus"
                  >
                    Perfil em funcionamento?
                  </label>
                  <select
                    className="pm-select pm-select-value-txt"
                    id="profileStatus"
                    {...register(`profileStatus`, {})}
                  >
                    <option value="1">Ativo</option>
                    <option value="2">Inativo</option>
                  </select>
                </div>
                {!isMobile && (
                  <button type="submit" className="pm-save-btn button-primary">
                    Salvar
                  </button>
                )}
              </div>
            </div>
          </form>
          <div className="profile-grants">
            <div className="grants-header">
              <h2>Permissões</h2>
              <span>
                {isMobile ? <>Todas</> : <>Selecionar todas as permissões</>}
              </span>
              <div className="pm-checkbox">
                <input type="checkbox" id="selectAll" />
                <label htmlFor="selectAll"></label>
              </div>
            </div>
            {mockGrantCategories.map((group) => (
              <div key={group.groupName} className="grant-group">
                <div className="group-name">
                  <img src={group.icon} onLoad={(e) => SVGInject(e.target)} />
                  <h3>{group.groupName}</h3>
                </div>
                {group.categories.map((category) => (
                  <div key={category.categoryName} className="grant-category">
                    <div
                      className="grant-category-header"
                      onClick={() => toggleCollapse(category.categoryName)}
                    >
                      <div
                        style={
                          collapsedCategories[category.categoryName]
                            ? { transform: `rotate(0deg)` }
                            : { transform: `rotate(180deg)` }
                        }
                      >
                        <img
                          src={arrowDown}
                          onLoad={(e) => SVGInject(e.target)}
                          alt="arrow"
                          className="pm-arrow-down"
                        />
                      </div>
                      <h5>{category.categoryName}</h5>
                    </div>
                    {!collapsedCategories[category.categoryName] && (
                      <div className="grants-list">
                        {category.grants.map((grant, index) => (
                          <div key={index} className="grant">
                            <div className="pm-checkbox">
                              <input
                                type="checkbox"
                                id={`grant-${category.categoryName}-${grant}`}
                                onChange={() =>
                                  handleGrantChange(
                                    group.groupName,
                                    category.categoryName,
                                    grant,
                                  )
                                }
                              />
                              <label
                                htmlFor={`grant-${category.categoryName}-${grant}`}
                              ></label>
                            </div>
                            <span>{grant}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
            {isMobile && (
              <button
                onClick={handleSubmit(onSubmit)}
                type="submit"
                className="pm-save-btn button-primary"
              >
                Salvar
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
