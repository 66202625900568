import { useEffect, useState } from "react"
import { ChevronDown } from "lucide-react"

interface Transaction {
  type: string
  requester: string
  requestDate: string
  accountNumber: string
  paymentDate: string
  amount: string
  status: string
}

export const mockTransactions: Transaction[] = [
  {
    type: `Boleto`,
    requester: `FREDERICO JOSE PELOPIDAS SILVEIRA`,
    requestDate: `05/02/2025`,
    accountNumber: `2539260-3`,
    paymentDate: `21/02/2025`,
    amount: `R$ 101,00`,
    status: `2 aprovações pendentes`,
  },
  {
    type: `Boleto`,
    requester: `FRANCISCO JOSE DA CUNHA`,
    requestDate: `05/02/2025`,
    accountNumber: `2539260-3`,
    paymentDate: `21/02/2025`,
    amount: `R$ 101,00`,
    status: `2 aprovações pendentes`,
  },
  {
    type: `Boleto`,
    requester: `JOSE DA SILVA SILVEIRA`,
    requestDate: `05/02/2025`,
    accountNumber: `2539260-3`,
    paymentDate: `21/02/2025`,
    amount: `R$ 101,00`,
    status: `2 aprovações pendentes`,
  },
]

interface TransactionsTableProps {
  onSelectionChange: (count: number) => void
  searchTerm: string
  selectAll: boolean
}

export const TransactionsTable = ({
  onSelectionChange,
  searchTerm,
  selectAll,
}: TransactionsTableProps) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([])

  useEffect(() => {
    if (selectAll) {
      setSelectedRows(mockTransactions.map((_, index) => index))
      onSelectionChange(mockTransactions.length)
    } else {
      setSelectedRows([])
      onSelectionChange(0)
    }
  }, [selectAll, onSelectionChange])

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedRows(mockTransactions.map((_, index) => index))
      onSelectionChange(mockTransactions.length)
    } else {
      setSelectedRows([])
      onSelectionChange(0)
    }
  }

  const handleSelectRow = (index: number, checked: boolean) => {
    const newSelection = checked
      ? [...selectedRows, index]
      : selectedRows.filter((i) => i !== index)

    setSelectedRows(newSelection)
    onSelectionChange(newSelection.length)
  }

  const isAllSelected = selectedRows.length === mockTransactions.length

  const filteredRows = mockTransactions.filter((transaction) =>
    transaction.requester.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  return (
    <table className="transactions-table">
      <thead>
        <tr>
          <th className="checkbox-column">
            <div className="cb-all-container">
              <input
                type="checkbox"
                id="selectAllCheck"
                checked={isAllSelected}
                onChange={(e) => handleSelectAll(e.target.checked)}
              />
              <label htmlFor="selectAllCheck"></label>
            </div>
          </th>
          <th>
            <div className="column-header">
              <span>Tipo de transação</span>
              <ChevronDown size={16} />
            </div>
          </th>
          <th>
            <div className="column-header">
              <span>Solicitação</span>
              <ChevronDown size={16} />
            </div>
          </th>
          <th>
            <div className="column-header">
              <span>Conta débito</span>
              <ChevronDown size={16} />
            </div>
          </th>
          <th>
            <div className="column-header">
              <span>Pagamento previsto</span>
              <ChevronDown size={16} />
            </div>
          </th>
          <th>
            <div className="column-header">
              <span>Valor total</span>
              <ChevronDown size={16} />
            </div>
          </th>
          <th>
            <div className="column-header">
              <span>Status</span>
              <ChevronDown size={16} />
            </div>
          </th>
          <th className="arrow-column"></th>
        </tr>
      </thead>
      <tbody>
        {filteredRows.map((transaction, index) => (
          <tr key={index}>
            <td className="checkbox-column">
              <div className="cb-container">
                <input
                  type="checkbox"
                  id={`transaction-${index}`}
                  checked={selectedRows.includes(index)}
                  onChange={(e) => handleSelectRow(index, e.target.checked)}
                />
                <label htmlFor={`transaction-${index}`}></label>
              </div>
            </td>
            <td>
              <div className="transaction-info">
                <span className="primary-info">{transaction.type}</span>
                <span className="secondary-info">{transaction.requester}</span>
              </div>
            </td>
            <td>
              <div className="transaction-info">
                <span className="primary-info">{transaction.requestDate}</span>
                <span className="secondary-info">{transaction.requester}</span>
              </div>
            </td>
            <td className="account-column">{transaction.accountNumber}</td>
            <td className="date-column">{transaction.paymentDate}</td>
            <td className="amount-column">{transaction.amount}</td>
            <td>
              <span className="status-badge">{transaction.status}</span>
            </td>
            <td className="arrow-column">
              <button className="btn-arrow">→</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
