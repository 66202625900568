import { HelpCircle, AlertCircle, Users } from "lucide-react"

export function HelpPanel() {
  return (
    <div className="help-panel">
      <div className="help-header">
        <HelpCircle size={20} />
        <h2>Dicas gerais</h2>
      </div>

      <div className="help-items">
        <div className="help-item">
          <div className="icon">
            <HelpCircle size={20} />
          </div>
          <div className="content">
            <h3>O que é a gestão de aprovações?</h3>
            <p>
              A Gestão de Aprovações é onde você pode configurar até qual valor
              uma transação irá precisar de aprovação e também quantas
              aprovações serão necessárias para que a transação seja efetivada.
            </p>
          </div>
        </div>

        <div className="help-item">
          <div className="icon">
            <AlertCircle size={20} />
          </div>
          <div className="content">
            <h3>Fique atento</h3>
            <p>
              Algumas transações como de Folha e Débito Automático não é
              possível alterar as aprovações.
            </p>
          </div>
        </div>

        <div className="help-item">
          <div className="icon">
            <Users size={20} />
          </div>
          <div className="content">
            <h3>Aprovações por usuários</h3>
            <p>
              A Gestão de Aprovações é onde você pode configurar até qual valor
              uma transação irá precisar de aprovação e também quantas
              aprovações serão necessárias para que a transação seja efetivada.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
