import { PaginationV2 } from "../../../../features/PaginationV2"
import { TransactionsTable } from "../components/TransactionsTable"
import { Search } from "lucide-react"
import { useResposive } from "../../../../../hooks/useResponsive"

interface IApprovalsPanelProps {
  tabs: { id: string; label: string }[]
  activeTab: string
  setActiveTab: (tab: string) => void
  search: string
  setSearch: (search: string) => void
  selectedCount: number
  setSelectedCount: (count: number) => void
  selectAll: boolean
  currentPage: number
  resultsPerPage: number
  mockTransactions: any[]
  handlePageChange: (page: number) => void
  handleResultsPerPageChange: (value: number) => void
  handleSelectAll: () => void
  navigate: (path: string) => void
}

const ApprovalsPanel = ({
  tabs,
  activeTab,
  setActiveTab,
  search,
  setSearch,
  selectedCount,
  setSelectedCount,
  selectAll,
  currentPage,
  resultsPerPage,
  mockTransactions,
  handlePageChange,
  handleResultsPerPageChange,
  handleSelectAll,
  navigate,
}: IApprovalsPanelProps) => {
  const { isTabletOrMobile } = useResposive()

  return (
    <>
      {isTabletOrMobile ? null : (
        <div className="approvals-header-container">
          <div className="approvals-header">
            <h1 className="approvals-title">Aprovações</h1>
          </div>
          <div className="ap-header-btn-container">
            <button
              onClick={() => navigate(`/approvals-management`)}
              className="ap-header-btn button-primary"
            >
              Gestão de aprovações
            </button>
          </div>
        </div>
      )}
      <div className="approvals-container">
        <div className="approvals-content">
          <div className="tabs">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={`tab-item ${activeTab === tab.id ? `active` : ``}`}
                onClick={() => setActiveTab(tab.id)}
              >
                {tab.label}
              </button>
            ))}
          </div>

          <p className="description">
            Confira as transações pendentes da sua aprovação e selecione para
            aprovar ou reprovar.
          </p>

          <div className="filters">
            <div className="search-container">
              <Search className="search-icon" />
              <input
                type="text"
                placeholder="Buscar"
                className="search-input"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            {/* <div className="filters-right">
              <button className="filter-button button-primary">Filtrar</button>
              <div className="date-filter">
                Solicitação: 06/01/2025 a 05/02/2025
              </div>
              <button className="accounts-button">Todas as contas</button>
            </div> */}
          </div>

          <div className="summary">
            <div className="summary-left">
              <button
                onClick={handleSelectAll}
                className="select-all button-primary"
              >
                {selectAll ? `Desmarcar todos` : `Selecionar todos`}
              </button>
              <div className="totals">
                <div className="total-item">
                  <span className="label">Selecionados</span>
                  <span className="value">
                    {selectedCount} de {mockTransactions?.length}
                  </span>
                </div>
                <div className="total-item">
                  <span className="label">Total</span>
                  <span className="value">R$ 0,00</span>
                </div>
                {/* <div className="total-item">
                  <span className="label">Total EUA</span>
                  <span className="value">US$ 0,00</span>
                </div> */}
              </div>
            </div>
            <div className="action-buttons">
              <button className="btn-reject">Reprovar</button>
              <button className="btn-approve button-primary">Aprovar</button>
            </div>
          </div>
          <div className="transactions-table-container">
            <TransactionsTable
              onSelectionChange={setSelectedCount}
              searchTerm={search}
              selectAll={selectAll}
            />
          </div>

          <PaginationV2
            currentPage={currentPage}
            totalPages={5}
            onPageChange={handlePageChange}
            totalResults={50}
            resultsPerPage={resultsPerPage}
            onResultsPerPageChange={handleResultsPerPageChange}
          />
        </div>
      </div>
    </>
  )
}

export default ApprovalsPanel
