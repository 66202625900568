import { useNavigate } from "react-router-dom"
import { Search } from "lucide-react"
import { useEffect, useState } from "react"
import {
  TransactionsTable,
  mockTransactions,
} from "./components/TransactionsTable"
import "./styles.scss"
import { PaginationV2 } from "../../../features/PaginationV2"
import { useResposive } from "../../../../hooks/useResponsive"
import ApprovalsPanel from "./Panel"
import SVGInject from "@iconfu/svg-inject"
import back from "../../../../assets/images/arrow_yellow.svg"

export const Approvals = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState(``)
  const [currentPage, setCurrentPage] = useState(1)
  const [resultsPerPage, setResultsPerPage] = useState(10)
  const [activeTab, setActiveTab] = useState(`pending`)
  const [selectedCount, setSelectedCount] = useState(0)
  const [selectAll, setSelectAll] = useState(false)

  const { isTabletOrMobile } = useResposive()

  const tabs = [
    { id: `pending`, label: `Pendentes` },
    { id: `inProgress`, label: `Em andamento` },
    { id: `completed`, label: `Concluídas` },
  ]

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    // Here you would typically fetch new data based on the new page
  }

  const handleResultsPerPageChange = (value: number) => {
    setResultsPerPage(value)
    setCurrentPage(1) // Reset to first page when changing results per page
    // Here you would typically fetch new data based on the new resultsPerPage
  }

  useEffect(() => {
    selectedCount === mockTransactions?.length
      ? setSelectAll(true)
      : setSelectAll(false)
  }, [selectedCount])

  const handleSelectAll = () => {
    setSelectAll(!selectAll)
  }

  return (
    <div
      className="approvals-wrapper"
      style={{
        padding: isTabletOrMobile ? `0` : `0 75px`,
        backgroundColor: isTabletOrMobile
          ? `var(--k4n-primary-color)`
          : `transparent`,
      }}
    >
      {isTabletOrMobile && (
        <div className="approvals-container-mobile">
          <div className="approvals-header-mobile">
            <button
              className="approvals-header-mobile-btn f-l"
              onClick={() => navigate(`/banking`)}
            >
              <img src={back} alt="" onLoad={(e) => SVGInject(e.target)} />
            </button>
            <h1 className="approvals-header-h1-mobile"> Aprovações </h1>
          </div>
        </div>
      )}
      <ApprovalsPanel
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        search={search}
        setSearch={setSearch}
        selectedCount={selectedCount}
        setSelectedCount={setSelectedCount}
        selectAll={selectAll}
        currentPage={currentPage}
        resultsPerPage={resultsPerPage}
        mockTransactions={mockTransactions}
        handlePageChange={handlePageChange}
        handleResultsPerPageChange={handleResultsPerPageChange}
        handleSelectAll={handleSelectAll}
        navigate={navigate}
      />
    </div>
  )
}
