import { useState } from "react"
import { ChevronDown, FileText, Edit2, Trash2, Plus } from "lucide-react"
import "./styles.scss"
import { HelpPanel } from "./components/HelpPanel"
import Header from "../../../features/Header"
import { CustomModal } from "k4n-components"
import DigitalSignature from "../../DigitalSignature/Form"
import key from "../../../../assets/images/key.svg"
import SVGInject from "@iconfu/svg-inject"
import EditApprovalModal from "./components/EditApprovalModal/EditApprovalModal"
import DeleteApprovalModal from "./components/DeleteApprovalModal/DeleteApprovalModal"
import CreateApprovalModal from "./components/CreateApprovalModal/CreateApprovalModal"

interface Configuration {
  id: number
  title: string
  description: string
  selected?: boolean
}

interface PaymentCard {
  id: number
  title: string
  icon: JSX.Element
  configurations: Configuration[]
}

export const ApprovalsManagement = () => {
  const [openCards, setOpenCards] = useState<number[]>([1])
  const [openModalEditApproval, setOpenModalEditApproval] = useState(false)
  const [openModalDeleteApproval, setOpenModalDeleteApproval] = useState(false)
  const [openModalCreateApproval, setOpenModalCreateApproval] = useState(false)
  const [authorizeModal, setAuthorizeModal] = useState(false)
  const [paymentCards, setPaymentCards] = useState<PaymentCard[]>([
    {
      id: 1,
      title: `Pagamento de boleto`,
      icon: <FileText size={20} />,
      configurations: [
        {
          id: 1,
          title: `Configuração 1`,
          description: `Todas as transações precisam de 1 aprovação`,
        },
        {
          id: 2,
          title: `Configuração 2`,
          description: `A partir de R$ 10.000,00 precisa de 2 aprovações`,
        },
      ],
    },
    {
      id: 2,
      title: `Pagamento de boleto via API`,
      icon: <FileText size={20} />,
      configurations: [
        {
          id: 1,
          title: `Configuração 1`,
          description: `Todas as transações precisam de 1 aprovação`,
        },
      ],
    },
  ])

  const toggleCard = (cardId: number) => {
    setOpenCards((prev) =>
      prev.includes(cardId)
        ? prev.filter((id) => id !== cardId)
        : [...prev, cardId],
    )
  }

  const toggleOpenModalEditApproval = () => {
    setOpenModalEditApproval((prev) => !prev)
  }

  const toggleOpenModalDeleteApproval = () => {
    setOpenModalDeleteApproval((prev) => !prev)
  }

  const toggleOpenModalCreateApproval = () => {
    setOpenModalCreateApproval((prev) => !prev)
  }

  const onSubmitEditApproval = (data) => {
    console.log(`Form Submitted edit approval`, { ...data })
  }

  const onSubmitDeleteApproval = () => {
    setAuthorizeModal(true)
  }

  const onSubmitCreateApproval = (data) => {
    console.log(`Form Submitted create approval`, { ...data })
    // setAuthorizeModal(true)
  }

  const authorize = () => {
    setAuthorizeModal(false)
    return
  }

  return (
    <>
      <Header />
      <div className="approval-container">
        <div className="approval-first-container">
          <div className="approval-management">
            <div className="approval-management-header">
              <h1>Gestão de Aprovações</h1>
            </div>
            <p>
              Selecione uma transação e configure os requisitos para aprovação.
              Você pode escolher a partir de qual valor será necessário uma
              aprovação e quantos operadores terão que aprovar a transação para
              que seja efetivada.
            </p>

            <div className="group">
              <h2 className="group-title">
                Grupo: ATENA SOLUCOES INFORMATICA LTDA
              </h2>

              {paymentCards.map((card) => (
                <div key={card.id} className="payment-card">
                  <div
                    className="payment-card-header"
                    onClick={() => toggleCard(card.id)}
                  >
                    <span className="icon">{card.icon}</span>
                    <span className="title">{card.title}</span>
                    <span className="config-count">
                      {card.configurations.length}
                      {` `}
                      {card.configurations.length === 1
                        ? `configuração`
                        : `configurações`}
                    </span>
                    <ChevronDown
                      className={`chevron ${
                        openCards.includes(card.id) ? `open` : ``
                      }`}
                      size={20}
                    />
                  </div>

                  <div
                    className={`payment-card-content ${
                      openCards.includes(card.id) ? `open` : ``
                    }`}
                  >
                    {card.configurations.map((config) => (
                      <div key={config.id} className={`config-item`}>
                        <div className="config-info">
                          <div className="config-title">{config.title}</div>
                          <div className="config-description">
                            {config.description}
                          </div>
                        </div>
                        <div className="config-actions">
                          <button
                            aria-label="Editar"
                            onClick={() => toggleOpenModalEditApproval()}
                          >
                            <Edit2 size={16} />
                          </button>
                          <button
                            aria-label="Excluir"
                            onClick={() => toggleOpenModalDeleteApproval()}
                          >
                            <Trash2 size={16} />
                          </button>
                        </div>
                      </div>
                    ))}

                    <button
                      className="add-config"
                      onClick={toggleOpenModalCreateApproval}
                    >
                      <Plus size={16} className="plus-icon" />
                      Adicionar configuração
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <HelpPanel />
        <EditApprovalModal
          isOpen={openModalEditApproval}
          onClose={toggleOpenModalEditApproval}
          onSubmit={onSubmitEditApproval}
          defaultEditApprovalValue={`0.00`}
          defaultEditApprovalQuantity={1}
        />
        <DeleteApprovalModal
          isOpen={openModalDeleteApproval}
          onClose={toggleOpenModalDeleteApproval}
          onSubmit={onSubmitDeleteApproval}
        />
        <CreateApprovalModal
          isOpen={openModalCreateApproval}
          onClose={toggleOpenModalCreateApproval}
          onSubmit={onSubmitCreateApproval}
        />
        <CustomModal
          isOpen={authorizeModal}
          onClose={() => {
            setAuthorizeModal(false)
          }}
          isBanking={true}
        >
          <div style={{ width: `120px`, margin: `0 auto` }}>
            <img
              src={key}
              alt="/"
              width="120"
              height="120"
              className="key-vector"
              onLoad={(e) => SVGInject(e.target)}
            />
          </div>
          <DigitalSignature handleSuccess={authorize} />
        </CustomModal>
      </div>
    </>
  )
}
