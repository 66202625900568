import React from "react"
import "./styles.scss"

interface IInputProps {
  id?: string
  name?: string
  label: string
  type: string
  required?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: string
  htmlFor?: string
}

export default function index({
  label,
  onChange,
  type,
  required,
  id,
  name,
  value,
  htmlFor,
}: IInputProps) {
  return (
    <div className="inputGroup k4n-input-label">
      <input
        type={type}
        required={required}
        onChange={onChange}
        id={id}
        name={name}
        value={value}
      />
      <label htmlFor={htmlFor} id={id}>
        {label}
      </label>
    </div>
  )
}
