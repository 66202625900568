import { useState } from "react"
import "./styles.scss"
import Pencil from "../../../assets/images/pencil.svg"
import SVGInject from "@iconfu/svg-inject"
import Remove from "../../../assets/images/clear_x.svg"
import { useNavigate } from "react-router-dom"
import Header from "../../features/Header"
import { useResposive } from "../../../hooks/useResponsive"
import { PaginationV2 } from "../../features/PaginationV2"

export const OperatorsManagement = () => {
  const [optionSelected, setOptionSelected] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [resultsPerPage, setResultsPerPage] = useState(10)
  const navigate = useNavigate()
  const { isMobile } = useResposive()
  const handleButtonClick = () => {
    if (optionSelected === 1) {
      navigate(`/profile-management`)
    } else {
      navigate(`/user-management`)
    }
  }
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    // Here you would typically fetch new data based on the new page
  }

  const handleResultsPerPageChange = (value: number) => {
    setResultsPerPage(value)
    setCurrentPage(1) // Reset to first page when changing results per page
    // Here you would typically fetch new data based on the new resultsPerPage
  }
  const mockData = [
    {
      headers: [
        `Status`,
        `Nome`,
        `Código`,
        `Perfil`,
        `Celular`,
        `CPF`,
        `Ações`,
      ],
      rows: [
        {
          status: 1,
          nome: `João`,
          codigo: `123123`,
          perfil: `SOCIO`,
          celular: `(11) 99168-4905`,
          cpf: `439.277.378-79`,
        },
        {
          status: 0,
          nome: `Maria`,
          codigo: `123123`,
          perfil: `BASICO`,
          celular: `(11) 99168-4905`,
          cpf: `439.277.378-79`,
        },
      ],
    },
    {
      headers: [
        `Status`,
        `Nome do Perfil`,
        `Serviços`,
        `Cartão de Crédito`,
        `Seguros`,
        `Conta Digital`,
        `Ações`,
      ],
      rows: [
        {
          status: 1,
          nomeDoPerfil: `SOCIO`,
          servicos: `3 permissões`,
          cartaoCredito: `2 permissões`,
          seguros: `1 permissão`,
          contaDigital: `8 permissões`,
        },
        {
          status: 0,
          nomeDoPerfil: `BASICO`,
          servicos: `2 permissões`,
          cartaoCredito: `1 permissão`,
          seguros: `0 permissões`,
          contaDigital: `5 permissões`,
        },
      ],
    },
  ]

  const { headers, rows } = mockData[optionSelected]

  return (
    <div className="op-page-container">
      <Header />
      <div className="op-management-container">
        <div className="op-management-content">
          <div className="op-manager">
            <div className="options">
              {[`Gestão de Usuários`, `Gestão de Perfis`].map(
                (label, index) => (
                  <div
                    className={
                      isMobile && index === 0 ? `options-btn-mobile` : ``
                    }
                    key={index}
                  >
                    <button
                      className={
                        optionSelected === index
                          ? `op-management-option-btn`
                          : `op-management-option-btn-disabled`
                      }
                      onClick={() => setOptionSelected(index)}
                    >
                      {label}
                    </button>
                    {isMobile && index === 0 && (
                      <div className="new-btn-mobile">
                        <button
                          onClick={handleButtonClick}
                          className="button-primary"
                        >
                          +
                        </button>
                      </div>
                    )}
                  </div>
                ),
              )}
              {!isMobile && (
                <div className="new-btn">
                  <button
                    onClick={handleButtonClick}
                    className="button-primary"
                  >
                    {optionSelected === 1 ? `NOVO PERFIL` : `NOVO USUÁRIO`}
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="op-table">
            <table className="table table-borderless">
              <thead>
                <tr>
                  {headers.map((header, i) => (
                    <th key={i} className="table-column-title" scope="col">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((row, i) => (
                  <tr key={i}>
                    {(Object.values(row) as (string | number)[]).map(
                      (cell, j) => (
                        <td key={j} className="mx-wd-145">
                          <div className="table-cell-center">
                            <div className="txt-overflow">
                              {j === 0 ? (
                                <div
                                  className="op-status"
                                  style={{
                                    backgroundColor:
                                      cell === 1 ? `green` : `red`,
                                  }}
                                ></div>
                              ) : (
                                <span className="row-txt-bold">
                                  {String(cell)}
                                </span>
                              )}
                            </div>
                          </div>
                        </td>
                      ),
                    )}
                    <td className="mx-wd-145">
                      <div className="table-cell-center">
                        <div className="txt-overflow actions-col">
                          <img
                            style={{ cursor: `pointer` }}
                            onLoad={(e) => SVGInject(e.target)}
                            src={Pencil}
                          />
                          <img
                            src={Remove}
                            className="ml-24"
                            style={{ cursor: `pointer` }}
                            onLoad={(e) => SVGInject(e.target)}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="op-pagination">
            <PaginationV2
              currentPage={currentPage}
              totalPages={5}
              onPageChange={handlePageChange}
              totalResults={50}
              resultsPerPage={resultsPerPage}
              onResultsPerPageChange={handleResultsPerPageChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
