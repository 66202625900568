import React, { useState } from "react"
import { ChevronLeft, ChevronRight } from "lucide-react"
import "./styles.scss"
import { useResposive } from "../../../hooks/useResponsive"

interface PaginationProps {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
  totalResults: number
  resultsPerPage: number
  onResultsPerPageChange: (value: number) => void
}

export const PaginationV2: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  totalResults,
  resultsPerPage,
  onResultsPerPageChange,
}) => {
  const { isMobile } = useResposive()

  return (
    <div className="list-controls">
      {!isMobile && (
        <div className="showing-dropdown">
          <select
            value={resultsPerPage}
            onChange={(e) => onResultsPerPageChange(Number(e.target.value))}
            className="showing-select"
          >
            <option value="10">Exibindo 10</option>
            <option value="20">Exibindo 20</option>
            <option value="50">Exibindo 50</option>
          </select>
        </div>
      )}
      <div className="pagination">
        <button
          type="button"
          className="pagination-arrow"
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
        >
          <ChevronLeft className="icon" />
        </button>
        <span className="page-text">Página</span>
        <input
          type="number"
          value={currentPage}
          onChange={(e) => onPageChange(Number(e.target.value))}
          className="page-input"
          min={1}
          max={totalPages}
        />
        <span className="page-text">de {totalPages}</span>
        <button
          type="button"
          className="pagination-arrow"
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(currentPage + 1)}
        >
          <ChevronRight className="icon" />
        </button>
        {!isMobile && (
          <span className="results-text">
            Resultado: {(currentPage - 1) * resultsPerPage + 1}-
            {Math.min(currentPage * resultsPerPage, totalResults)} de{` `}
            {totalResults}
          </span>
        )}
      </div>
    </div>
  )
}
