import { CustomModal } from "k4n-components"
import "./styles.scss"

const DeleteApprovalModal = ({ isOpen, onClose, onSubmit }) => {
  return (
    <CustomModal isOpen={isOpen} onClose={onClose} containerBg={`#ffff`}>
      <div className="modal-content-delete-approval">
        <div className="modal-title-delete-approval">
          Excluir Pagamento de Boleto
        </div>
        <p className="modal-description-delete-approval">
          Atenção, você está prestes a excluir uma configuração de pagamento de
          boleto, essa ação é irreversível.
        </p>
        <div className="modal-buttons-delete-approval">
          <button onClick={onClose} className="button-primary-outlined">
            Cancelar
          </button>
          <button onClick={onSubmit} className="button-primary">
            Confirmar
          </button>
        </div>
      </div>
    </CustomModal>
  )
}

export default DeleteApprovalModal
