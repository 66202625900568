interface StepIndicatorProps {
  currentStep: number
  totalSteps: number
}

export const StepIndicator = ({
  currentStep,
  totalSteps,
}: StepIndicatorProps) => {
  return (
    <div className="step-indicator">
      {Array.from({ length: totalSteps }, (_, i) => (
        <div
          key={i}
          className={`step ${i + 1 <= currentStep ? `active` : ``}`}
        />
      ))}
    </div>
  )
}
